<template>
  <div class="vacancies-list-item ds-grid ds-margin-y-24 ds-margin-x-0 ds-align-items-center">
    <div
      class="ds-grid__col ds-grid__col--12 ds-grid__col--no-gutter ds-md-margin-bottom-0"
      :class="{'ds-md-grid__col--5 ds-margin-bottom-24': !compact, 'ds-md-grid__col--11 ds-padding-right-24': compact}"
    >
      <DsLink
        :url="item.job.url"
        :content="item.job.title"
        color="black-green"
        nuxt
        :heading="!compact"
        class="vacancies-list-item__link"
        :class="{ 'vacancies-list-item__link--hover': hovering }"
        :icon-right="isScreenSmall ? { category: 'arrows', name: 'arrow-right', ariaLabel: item.job.title } : null"
        @mouseenter="hovering = true"
        @mouseleave="hovering = false"
      />
    </div>
    <template v-if="!compact">
      <div
        class="ds-grid__col ds-grid__col--12 ds-md-grid__col--3 ds-margin-bottom-8 ds-md-margin-bottom-0"
        :class="{ 'ds-grid__col--no-gutter' : isScreenSmall }"
      >
        <DsLink
          v-if="item.department.url"
          :url="item.department.url"
          :content="item.department.title"
          color="black-green"
          variant="tertiary"
          nuxt
        />
        <button
          v-else
          class="ds-button-link ds-button-link--tertiary ds-button-link--black-green"
          @click="$emit('item-clicked', item.department)"
        >
          {{ item.department.title }}
        </button>
      </div>
      <div
        class="ds-grid__col ds-grid__col--12 ds-md-grid__col--3"
        :class="{ 'ds-grid__col--no-gutter' : isScreenSmall }"
      >
        <DsLink
          v-if="item.office.url"
          :url="item.office.url"
          :content="item.office.title"
          color="black-green"
          variant="tertiary"
          nuxt
        />
        <button
          v-else
          class="ds-button-link ds-button-link--tertiary ds-button-link--black-green"
          @click="$emit('item-clicked', item.office)"
        >
          {{ item.office.title }}
        </button>
      </div>
    </template>
    <div
      v-if="!isScreenSmall"
      class="ds-grid__col ds-grid__col--1 ds-grid__col--no-gutter ds-display-flex ds-justify-content-flex-end"
    >
      <DsLink
        class="vacancies-list-item__icon"
        :url="item.job.url"
        color="black-green"
        :content="{ category: 'arrows', name: 'arrow-right', ariaLabel: item.job.title }"
        :class="{'vacancies-list-item__icon--hover': hovering }"
        @mouseenter="hovering = true"
        @mouseleave="hovering = false"
      />
    </div>
  </div>
</template>

<script setup>
import { DsLink } from 'awds'
const { isScreenSmall } = useScreen()

defineEmits(['item-clicked'])
defineProps({
  item: {
    type: Object,
    required: true
  },
  asWidget: {
    type: Boolean,
    default: false
  },
  compact: {
    type: Boolean,
    default: false
  }
})

const hovering = ref(false)
</script>
<style lang="scss" scoped>
@import 'awds/foundation-vars.scss';
@import 'awds/foundation-mixins.scss';

.vacancies-list-item {
  &__icon {
    transform: translateX(- 4px);
    transition: transform 0.15s ease-out;
  }

  @include screen-only-small () {
    &__link {
      width: 100%;

      :deep(.ds-text-icon) {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  &__icon--hover {
    color: $color-green !important;
    transform: translateX(0);
  }

  &__link--hover {
    color: $color-green !important;
  }
}
</style>
