<template>
  <LoadingSvg ref="spinnerIcon" class="p-spinner" :style="style" />
</template>

<script setup>
import LoadingSvg from '../assets/icons/loading-small.svg'

const props = defineProps({
  size: {
    type: Number,
    default: 24
  }
})

const style = computed(() => ({
  width: props.size + 'px',
  height: props.size + 'px'
}
))
</script>

<style lang="scss" scoped>
.p-spinner {
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
